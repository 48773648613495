.hint[data-v-207c1cc5] {
  background-image: url(../img/aimodal.e5856c2b.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  padding: 15px 35px;
}
.hint_title[data-v-207c1cc5] {
  font-size: 26px;
  font-weight: 700;
  background-image: -webkit-linear-gradient(left, #000, #0a5baa, #0f39a5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 5px;
  border-bottom: 1px solid #4279b1;
  letter-spacing: 3px;
  display: inline-block;
}
.hint_content[data-v-207c1cc5] {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #3c3c3d;
}
.hint_content span[data-v-207c1cc5] {
  margin-left: 5px;
}
[data-v-207c1cc5] .el-alert__title {
  letter-spacing: 2px;
}
[data-v-207c1cc5] .el-divider--horizontal {
  margin: 0 0 15px;
}
.record[data-v-207c1cc5] {
  font-size: 14px;
  color: #333;
  margin-bottom: 3px;
  font-weight: 600;
}
.record[data-v-207c1cc5]::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}
.sj_style[data-v-207c1cc5] {
  width: 18px;
  height: 18px;
  margin-right: 3px;
  position: relative;
  top: 6px;
}
.el-form--inline .el-form-item[data-v-207c1cc5] {
  margin-right: 5px !important;
}
.bank_hint[data-v-207c1cc5] {
  margin-left: 10px;
}
[data-v-207c1cc5] .el-alert--success.is-light {
  background-color: #dee9cb;
  color: #778d4f;
}
.stock[data-v-207c1cc5] {
  border: 1px solid #ededed;
  border-radius: 4px;
  margin-top: 10px;
  padding: 20px 15px;
}
.stock_hint[data-v-207c1cc5] {
  margin-top: 15px;
  color: #e6a23c;
}
.icon-wenhao[data-v-207c1cc5] {
  color: red;
  margin-left: 5px;
  font-size: 13px;
  cursor: pointer;
}
.hint_set[data-v-207c1cc5] {
  text-decoration: underline;
  cursor: pointer;
}
.hint_btn[data-v-207c1cc5] {
  margin-bottom: 10px;
}
[data-v-207c1cc5] .el-tabs--border-card>.el-tabs__content {
  padding: 5px 10px;
}
.temp_each[data-v-207c1cc5] {
  padding: 2px 5px;
  border: 1px solid var(--themeColor, #17a2b8);
  color: var(--themeColor, #17a2b8);
  font-size: 14px;
  cursor: pointer;
  margin: 5px 0 0 5px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
}
.temp_style[data-v-207c1cc5] {
  text-align: right;
}
.del_icon[data-v-207c1cc5] {
  margin-left: 8px;
  color: red;
  font-size: 13px;
}